import axios from "axios"
import store from "@/store"

// function getToken() {
//   return localStorage.token;
// }

let client = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
});

// client.interceptors.request.use(
//   config => {
//     config.headers.Authorization = `Bearer ${getToken()}`;

//     return config;
//   },
//   error => Promise.reject(error)
// );

export const MetadataRepository = {
  endpoint: "api/metadata.json",
  getAll() {
    return client.get(this.endpoint);
  },
  get(id) {
    return client.get(`${this.endpoint}/${id}`);
  },
  create(obj) {
    return client.post(this.endpoint, obj, {
      onUploadProgress: uploadEvent => {
        let upload = uploadEvent.loaded / uploadEvent.total
        store.commit('uploading/progress', upload)
      }
    })
  },
  update(obj) {
    return client.put(`${this.endpoint}/${obj.id}`, obj);
  },
  delete(id) {
    return client.delete(`${this.endpoint}/${id}`);
  },
};
