export default {
  namespaced: true,
  state: {
    display: false,
    color: 'success',
    timeout: 4000,
    text: '',
  },
  getters: {
  },
  mutations: {
    show(state, notification) {
      state.display = true
      state.text = notification.text
      state.color = notification.color
      state.timeout = notification.timeout
    },
    set(state, val) {
      state.display = val
    }
  },
  actions: {
  }
}
