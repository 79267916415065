// import { UsersRepository } from '@/api/repositories'
import { uuid } from 'vue-uuid'

export default {
  namespaced: true,
  state: {
    user: '',
    token: ''
  },
  getters: {
  },
  mutations: {
    setLocalUser (state, user) {
      state.user = user
    },
  },
  actions: {
    getLocalUser ({ commit }) {
      let user = JSON.parse(localStorage.getItem('user'))
       if (user) {
        commit('setLocalUser', user)
       } else {
        user = uuid.v4()
        localStorage.setItem('user', JSON.stringify(user)),
        commit('setLocalUser', user)
       }
    }
  }
}
