<template>
  <div>
    <v-row align="start" justify="space-around">
      <v-col cols="12" class="text-center">
        <v-card flat color="transparent">
          <v-card-title class="display-1">
            <v-spacer/>
            ¿Cómo geolocalizar y extraer <br v-if="$vuetify.breakpoint.xsOnly"> tus metadatos?
            <v-spacer/>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" lg="3" v-for="item in steps" :key="item.id">
                <v-card-title primary-title>
                  <v-spacer/>
                  <v-icon size="56" color="primary">{{ item.icon}}</v-icon>
                  <v-spacer/>
                </v-card-title>
                <v-card-text class="body-1 black--text" v-html="item.text"></v-card-text>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    steps: [
      {
        icon: 'mdi-upload',
        text: 'Precarga tus imágenes'
      },
      {
        icon: 'mdi-map-marker',
        text: 'Las imágenes con datos de geolocalización <br> se mostrarán en el mapa'
      },
      {
        icon: 'mdi-eye',
        text: 'Visualiza todos los metadatos en formato tabla'
      },
      {
        icon: 'mdi-file-excel',
        text: 'Exporta todos los datos en un fichero *.csv'
      }      
    ]
  })
}
</script>
