import { MetadataRepository } from '@/api/repositories'

export default {
  namespaced: true,
  state: {
    dialog: false,
    metadata: [],
  },
  getters: {
  },
  mutations: {
    dialog (state, val) {
      state.dialog = val
    },
    initMetadata (state) {
      state.metadata = []
    },
    addMetadata (state, metadata) {
      state.metadata = metadata
    },
  },
  actions: {
    async upload({ state, rootState }) {
      // this.commit('uploading/dialog', true)
      const body = {
        user: rootState.users.user,
        date: new Date(),
        metadata: state.metadata
      }
      await MetadataRepository.create(body)
        // .then(() => {
        //   this.commit('notification/show', {
        //     text: 'Metadatos subidos correctamente',
        //     color: 'success'
        //   })
        // })
        // .catch(() => {
        //   this.commit('notification/show', {
        //     text: 'Ha ocurrido un error al subir los Metadatos',
        //     color: 'error'
        //   })
        // })
      // this.commit('uploading/dialog', false)
    },
  }
}
