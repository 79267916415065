<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="pt-2 pb-2">
          Subiendo imágenes...
          <v-progress-linear
            v-model="progress"
            color="white"
            class="mb-0"
          ></v-progress-linear>
          <div class="text-right">
            {{ progress.toFixed(1) }}%
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  computed: {
    dialog () {
      return this.$store.state.uploading.dialog
    },
    progress () {
      return this.$store.getters['uploading/progress']
    }
  }
}
</script>