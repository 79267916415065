<template>
  <div>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-row align="center" justify="start" class="headline">
        <img src="@/assets/images/logo.png" height="40px" width="40px" class="mx-4" >
        Extractor de metadatos online
      </v-row> 
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data: () => ({

  })
}
</script>
