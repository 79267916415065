import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import UUID from 'vue-uuid';
import VueAnalytics from 'vue-analytics'
import VueMeta from 'vue-meta' 

Vue.use(UUID);

Vue.use(VueAnalytics, {
  id: 'UA-109235361-2',
  router
})

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
