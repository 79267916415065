<template>
  <div>

    <v-row align="start" justify="space-around">

      <v-col cols="12" md="9" class="text-left">
        <v-card flat color="transparent">
          <v-card-title class="display-1">
            <v-spacer/>
            <span class="text-center">
              Una herramienta <strong>online gratuita</strong> que te permitirá
              <br>
              <span class="grey--text text--darken-1">¡Sin login y sin instalar ningún componente!</span>
            </span>
            <v-spacer/>
          </v-card-title>
          <v-card-text class="body-1 text-center black--text">
            <v-row>
              
              <v-col cols="6" sm="4">
                <p><v-icon size="56" color="primary">mdi-numeric-1</v-icon></p>
                <div>Visualizar los metadatos de múltiples imágenes la vez</div>
              </v-col>

              <v-col cols="6" sm="4">
                <p><v-icon size="56" color="primary">mdi-numeric-2</v-icon></p>
                <div>Geolocalizar en un mapa tus fotos</div>
              </v-col>

              <v-col cols="6" sm="4">
                <p><v-icon size="56" color="primary">mdi-numeric-3</v-icon></p>
                <div>Exportar a un fichero CSV los metadatos de tus imágenes</div>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="9">
        <v-card flat color="transparent">
          <v-card-title class="display-1 text-center">
            <v-spacer/>
            ¿Que son los Metadatos y para <br v-if="$vuetify.breakpoint.xsOnly"> qué sirven?
            <v-spacer/>
          </v-card-title>
          <v-card-text>
            <v-row class="text-center body-1 black--text">

              <v-col cols="12" class="text-left">
                <p><span class="font-weight-bold"><a href="https://es.wikipedia.org/wiki/Metadatos" target="_blank">Metadato</a> en simples palabras:</span> es lo que describe a un dato.
                Gracias a ellos podemos convertir los <strong>datos no estructurados</strong> a <strong>datos estructurados</strong> y
                conocer información básica y útil de nuestros datos, como por ejemplo:</p> 
              </v-col>

              <v-col cols="6" sm="4">
                <p><v-icon size="56" color="primary">mdi-certificate</v-icon></p>
                <div>El autor o creador del dato</div>
              </v-col>

              <v-col cols="6" sm="4">
                <p><v-icon size="56" color="primary">mdi-clock</v-icon></p>
                <div>Fecha y hora de <br v-if="!$vuetify.breakpoint.xsOnly"> creación ó modificación del dato</div>
              </v-col>

              <v-col cols="6" sm="4">
                <p><v-icon size="56" color="primary">mdi-weight</v-icon></p>
                <div>Tamaño o peso <br v-if="$vuetify.breakpoint.xsOnly"> de los datos</div>
              </v-col>

              <v-col cols="6" sm="4">
                <p><v-icon size="56" color="primary">mdi-earth</v-icon></p>
                <div>Geolocalización</div>
              </v-col>

              <v-col cols="6" sm="4">
                <p><v-icon size="56" color="primary">mdi-drone</v-icon></p>
                <div>Equipo utilizado</div>
              </v-col>

              <v-col cols="6" sm="4">
                <p><v-icon size="56" color="primary">mdi-file-question</v-icon></p>
                <div>Tipo de archivo</div>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="start" justify="space-around">
      <v-col cols="12" md="9">
        <v-card-title class="display-1 text-center">
          <v-spacer/>
          Datos no estructurados
          <v-spacer/>
        </v-card-title>
        <v-card-text class="text-left body-1 black--text">
          <p>Cada vez que hacemos una foto con un smartphone, una tablet, una cámara digital o un dron; el archivo de la imagen contiene unos datos (<a href="https://es.wikipedia.org/wiki/Metadatos" target="_blank">metadatos</a>)
            que revelan información del tipo: fecha y hora en la que fue tomada la fotografía, el modelo del dispositivo y versión del sistema operativo, los parámetros
            utilizados para sacar la foto (tiempo de exposición, apertura de diafragma, distancia focal…).</p>

          <p>
            <v-row justify="space-around">
              <v-col cols="4" class="text-center">
                <v-icon size="56" color="primary">mdi-image</v-icon>
              </v-col>
              <v-col cols="4" class="text-center">
                <v-icon size="56" color="primary">mdi-music</v-icon>
              </v-col>
              <v-col cols="4" class="text-center">
                <v-icon size="56" color="primary">mdi-video</v-icon>
              </v-col>
            </v-row>
          </p>

          <p>En caso de tener activada la localización en el smartphone, tablet, cámara digital o dron, la información que obtendremos con los metadatos también incluirá
            información GPS (latitud, longitud y altitud). 
            Toda esta información está organizada según un estándar de metadatos multimedia llamado EXIF (Exchangeable Image Format), que es el que permite dotar de
            información enriquecida a los formatos de imágenes JPG, TIFF, RIFF y WAVE.</p>
        </v-card-text>
      </v-col>
    </v-row>

    <v-row align="start" justify="space-around">
      <v-col cols="12" md="9">
        <v-card-title class="display-1 text-center">
          <v-spacer/>
          Datos estructurados
          <v-spacer/>
        </v-card-title>
        <v-card-text class="body-1 black--text">
          <p>Son aquellos datos que están en filas y columnas, es decir, en formato tabla.</p>
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            class="elevation-0 transparent"
          ></v-data-table>
        </v-card-text>
      </v-col>
    </v-row>
    
    <v-row align="start" justify="space-around">
      <v-col cols="12" md="9" class="text-left">
        <v-card flat color="transparent">
          <v-card-title class="display-1 text-center">
            <v-spacer/>
            ¿Cómo nació metadato.org?
            <v-spacer/>
          </v-card-title>
          <v-card-text class="body-1 black--text">
            <p>Cuando los fundadores de metadato se vieron en la necesidad de analizar grandes volúmenes de datos procedentes de inspecciones visuales con drones,
              se dieron cuenta que no existía una herramienta gratuita online que les permitiera exportar los metadatos de sus archivos. A raíz de esto decidieron
              crear metadato una organización que tiene como fin poder ayudar a particulares, autónomos y empresas a visualizar, localizar y exportar todos
              sus metadatos de forma fácil, rápida y de manera intuitiva.</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    steps: [
      {
        icon: 'mdi-upload',
        text: 'Precarga tus imágenes'
      },
      {
        icon: 'mdi-map-marker',
        text: 'Las imágenes con datos de geolocalización <br> se mostrarán en el mapa'
      },
      {
        icon: 'mdi-eye',
        text: 'Visualiza todos los metadatos en formato tabla'
      },
      {
        icon: 'mdi-file-excel',
        text: 'Exporta todos los datos en un fichero *.csv'
      }      
    ],
    headers: [
       { text: 'Nombre', value: 'name', align: 'left', sortable: false },
       { text: 'Fecha', value: 'date', align: 'left', sortable: false },
       { text: 'Hora', value: 'time', align: 'left', sortable: false },
       { text: 'Tamaño', value: 'size', align: 'left', sortable: false }
    ],
    items: [
      { name: 'Metadato 1', date: '12/04/2019', time: '14:30', size: '300 MB' },
      { name: 'Metadato 2', date: '11/09/2008', time: '10:30', size: '15 MB' },
      { name: 'Metadato 3', date: '29/11/2012', time: '19:58', size: '400 GB' },
      { name: 'Metadato 4', date: '16/07/2011', time: '13:45', size: '1 TB' },
    ]
  })
}
</script>
