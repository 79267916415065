<template>
  <v-snackbar top v-model="display" :color="color" :timeout="timeout">
    {{ text }}
    <v-btn dark icon @click="close()">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>

</template>

<script>
  export default {
    computed: {
      display: {
        // getter
        get () {
          return this.$store.state.notification.display
        },
        // setter
        set (newValue) {
          this.$store.commit('notification/set', newValue)
        }
      },
      color () {
        return this.$store.state.notification.color
      },
      timeout () {
        return this.$store.state.notification.timeout
      },
      text () {
        return this.$store.state.notification.text
      },
    },
    methods: {
      close () {
        this.$store.commit('notification/set', false)
      }
    }
  }
</script>
