<template>
  <v-app>
    <header-component />
    <v-content>
      <router-view/>
    </v-content>
    <footer-component />
    <notification />
  </v-app>
</template>

<style>

.v-card__title {
  word-break: keep-all !important;
}

#map {
  width: 100%;
  height: 50vh;
}

.drag-drop-border {
  border-style: dashed !important;
  border-width: 3px dashed !important;
  background-color: transparent !important;
  cursor: pointer !important;
}

td {
  font-size: 1rem !important;
  font-weight: 400;
  letter-spacing: 0.03125em !important;
  line-height: 1.5rem;
}

#metadataTable .v-data-table__wrapper {
  height:calc(100vh - 150px) !important;
}
</style>

<script>
import HeaderComponent from '@/components/HeaderComponent'
import FooterComponent from '@/components/FooterComponent'
import Notification from '@/components/Notification'

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Visualizador y extractor de metadatos',
    // override the parent template and just use the above title only
    titleTemplate: null,
    meta: [
      { name: 'description', content: 'Herramienta online para visualizar, exportar y geolocalizar tus imágenes a partir de los metadatos.' }, 
      { name: "keywords", content: "metadato,geolocalizar,exportar,visualizar,imagenes,online" },    
      { property: 'og:site_name', content: 'Metadato.org' },
      { property: 'og:title', content: 'Visualizador y extractor de metadatos' },
      { property: 'og:url', content: 'https://www.metadato.org' },
      { property: 'og:description', content: 'Herramienta online para visualizar, exportar y geolocalizar tus imágenes a partir de los metadatos.' },
      { property: 'og:image', content: 'https://www.metadato.org/logo.png' },
      { property: 'og:type', content: 'website' },
      { property: 'og:locale', content: 'es_ES' },
    ],
    link: [{ rel: 'canonical', href: 'https://www.metadato.org' }]
  },
  components:{
    HeaderComponent,
    FooterComponent,
    Notification
  },
  data: () => ({
    //
  }),
  created () {
    this.$store.dispatch('users/getLocalUser')
  }
};
</script>
