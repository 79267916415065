<template>
  <div>
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen 
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title>Metadatos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="close()">Cerrar</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!-- Metadata Table -->
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <v-data-table
                id="metadataTable"
                :headers="headers"
                :items="metadata"
                fixed-header
                :items-per-page="15"
                class="elevation-0"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    headers: [
      {text: "ImageDescription", value: "ImageDescription", align: "left", sortable: true, width: '200px'},
      {text: "Make", value: "Make", align: "left", sortable: true, width: '200px'},
      {text: "Model", value: "Model", align: "left", sortable: true, width: '200px'},
      {text: "Orientation", value: "Orientation", align: "left", sortable: true, width: '200px'},
      {text: "XResolution", value: "XResolution", align: "left", sortable: true, width: '200px'},
      {text: "YResolution", value: "YResolution", align: "left", sortable: true, width: '200px'},
      {text: "ResolutionUnit", value: "ResolutionUnit", align: "left", sortable: true, width: '200px'},
      {text: "Software", value: "Software", align: "left", sortable: true, width: '200px'},
      {text: "DateTime", value: "DateTime", align: "left", sortable: true, width: '200px'},
      {text: "YCbCrPositioning", value: "YCbCrPositioning", align: "left", sortable: true, width: '200px'},
      {text: "ExifIFDPointer", value: "ExifIFDPointer", align: "left", sortable: true, width: '200px'},
      {text: "GPSInfoIFDPointer", value: "GPSInfoIFDPointer", align: "left", sortable: true, width: '200px'},
      {text: "ExposureTime", value: "ExposureTime", align: "left", sortable: true, width: '200px'},
      {text: "FNumber", value: "FNumber", align: "left", sortable: true, width: '200px'},
      {text: "ExposureProgram", value: "ExposureProgram", align: "left", sortable: true, width: '200px'},
      {text: "ISOSpeedRatings", value: "ISOSpeedRatings", align: "left", sortable: true, width: '200px'},
      {text: "ExifVersion", value: "ExifVersion", align: "left", sortable: true, width: '200px'},
      {text: "DateTimeOriginal", value: "DateTimeOriginal", align: "left", sortable: true, width: '200px'},
      {text: "DateTimeDigitized", value: "DateTimeDigitized", align: "left", sortable: true, width: '200px'},
      {text: "ComponentsConfiguration", value: "ComponentsConfiguration", align: "left", sortable: true, width: '200px'},
      {text: "CompressedBitsPerPixel", value: "CompressedBitsPerPixel", align: "left", sortable: true, width: '200px'},
      {text: "ShutterSpeedValue", value: "ShutterSpeedValue", align: "left", sortable: true, width: '200px'},
      {text: "ApertureValue", value: "ApertureValue", align: "left", sortable: true, width: '200px'},
      {text: "ExposureBias", value: "ExposureBias", align: "left", sortable: true, width: '200px'},
      {text: "MaxApertureValue", value: "MaxApertureValue", align: "left", sortable: true, width: '200px'},
      {text: "SubjectDistance", value: "SubjectDistance", align: "left", sortable: true, width: '200px'},
      {text: "MeteringMode", value: "MeteringMode", align: "left", sortable: true, width: '200px'},
      {text: "LightSource", value: "LightSource", align: "left", sortable: true, width: '200px'},
      {text: "Flash", value: "Flash", align: "left", sortable: true, width: '200px'},
      {text: "FocalLength", value: "FocalLength", align: "left", sortable: true, width: '200px'},
      {text: "FlashpixVersion", value: "FlashpixVersion", align: "left", sortable: true, width: '200px'},
      {text: "ColorSpace", value: "ColorSpace", align: "left", sortable: true, width: '200px'},
      {text: "PixelXDimension", value: "PixelXDimension", align: "left", sortable: true, width: '200px'},
      {text: "PixelYDimension", value: "PixelYDimension", align: "left", sortable: true, width: '200px'},
      {text: "InteroperabilityIFDPointer", value: "InteroperabilityIFDPointer", align: "left", sortable: true, width: '200px'},
      {text: "ExposureIndex", value: "ExposureIndex", align: "left", sortable: true, width: '200px'},
      {text: "FileSource", value: "FileSource", align: "left", sortable: true, width: '200px'},
      {text: "SceneType", value: "SceneType", align: "left", sortable: true, width: '200px'},
      {text: "CustomRendered", value: "CustomRendered", align: "left", sortable: true, width: '200px'},
      {text: "ExposureMode", value: "ExposureMode", align: "left", sortable: true, width: '200px'},
      {text: "WhiteBalance", value: "WhiteBalance", align: "left", sortable: true, width: '200px'},
      {text: "DigitalZoomRation", value: "DigitalZoomRation", align: "left", sortable: true, width: '200px'},
      {text: "FocalLengthIn35mmFilm", value: "FocalLengthIn35mmFilm", align: "left", sortable: true, width: '200px'},
      {text: "SceneCaptureType", value: "SceneCaptureType", align: "left", sortable: true, width: '200px'},
      {text: "GainControl", value: "GainControl", align: "left", sortable: true, width: '200px'},
      {text: "Contrast", value: "Contrast", align: "left", sortable: true, width: '200px'},
      {text: "Saturation", value: "Saturation", align: "left", sortable: true, width: '200px'},
      {text: "Sharpness", value: "Sharpness", align: "left", sortable: true, width: '200px'},
      {text: "SubjectDistanceRange", value: "SubjectDistanceRange", align: "left", sortable: true, width: '200px'},
      {text: "GPSVersionID", value: "GPSVersionID", align: "left", sortable: true, width: '200px'},
      {text: "GPSLatitudeRef", value: "GPSLatitudeRef", align: "left", sortable: true, width: '200px'},
      {text: "GPSLatitude", value: "GPSLatitude", align: "left", sortable: true, width: '200px'},
      {text: "GPSLongitudeRef", value: "GPSLongitudeRef", align: "left", sortable: true, width: '200px'},
      {text: "GPSLongitude", value: "GPSLongitude", align: "left", sortable: true, width: '200px'},
      {text: "GPSAltitudeRef", value: "GPSAltitudeRef", align: "left", sortable: true, width: '200px'},
      {text: "GPSAltitude", value: "GPSAltitude", align: "left", sortable: true, width: '200px'},
      {text: "GPSCoordinates", value: "GPSCoordinates", align: "left", sortable: true, width: '200px'},
      // {text: "undefined", value: "undefined", align: "left", sortable: true, width: '200px'},
      // {text: "thumbnail", value: "thumbnail", align: "left", sortable: true, width: '200px'},
      // {text: "MakerNote", value: "MakerNote", align: "left", sortable: true, width: '200px'}
    ]
  }),
  computed: {
    dialog () {
      return this.$store.state.metadata.dialog
    },
    metadata () {
      return this.$store.state.metadata.metadata
    }
  },
  methods: {
    close () {
      this.$store.commit('metadata/dialog', false)
    }
  }
}
</script>