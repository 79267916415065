<template>
  <div>
    <v-container>
      <v-row justify="space-around" class="fill-height">
        <!-- File Upload -->
        <v-col cols="12" md="5" lg="4">
          <v-row align="center">
            <v-col cols="12">
              <v-row no-gutters align="center">
                <span class="display-1">
                  Geolocalizar y leer imágenes
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon color="primary" dark v-on="on">
                        <v-icon>mdi-help-circle</v-icon>
                      </v-btn>
                    </template>
                    <span class="body-1">
                      Cuando geolocalizas y extraes los metadatos de las imágenes, las mismas no se envían a ningún servidor,
                      <br>
                      sólo se guardan los registros de la geolocalización para tener una trazabilidad de uso.
                    </span>
                  </v-tooltip>
                </span>
              </v-row>
            </v-col>
            <!-- File Input -->
            <v-col cols="12" class="text-center d-none">
              <input
                ref="file_input"
                locale="es"
                accept="image/*"
                multiple="multiple"
                type="file"
                @change="getExif()"
                @blur="$v.files.$touch()">
              <!-- <v-file-input
                ref="file_input"
                v-model="files"
                :error-messages="filesErrors"
                label="Haz click aquí para subir tus imágenes"
                hint="Las imágenes tener un tamaño menor a 10 MB cada una."
                locale="es"
                accept="image/*"
                persistent-hint
                multiple
                show-size
                counter
                outlined
              ></v-file-input> -->
            </v-col>
            <!-- Drag and Drop -->
            <v-col cols="12" @click="addFilesClick()" @drop.prevent="addFilesDrop($event)" @dragover.prevent>
              <v-card flat class="drag-drop-border" :style="$v.$invalid && $v.$dirty ? 'border-color: #ff5252 !important' : 'border-color: #1976d2 !important'">
                <v-card-text class="body-1">
                  <v-container>
                    <v-row>

                      <v-col cols="12" class="text-center black--text" v-if="files.length > 0">
                        <div class="headline">Imágenes subidas</div>
                        <div><v-icon class="my-4" size="60" :color="$v.$invalid && $v.$dirty ? 'error' : 'primary'">mdi-cloud-upload</v-icon></div>
                        {{ files.length }} archivo<span v-if="metadata.length !== 1">s</span> ({{ fileSize }} en total)
                        <v-btn icon @click.stop="removeFiles()">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>

                      <v-col cols="12" class="text-center" :class="$v.$invalid && $v.$dirty ? 'error--text' : 'black--text'" v-else>
                        <div class="headline">Haz click aquí</div>
                        <div class="body-1">ó</div>
                        <div class="headline">Arrastra tus imágenes</div>
                        <v-icon class="my-4" size="56" :color="$v.$invalid && $v.$dirty ? 'error' : 'primary'">mdi-cloud-upload</v-icon>
                        <div class="error--text" v-if="$v.$invalid && $v.$dirty">{{ filesErrors[0] }}</div>
                        <div v-else>Las imágenes deben tener un tamaño menor a 10 MB cada una.</div>
                      </v-col>
                    </v-row>

                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- Files -->
            <v-col cols="12" v-if="metadataLoaded">
              <v-card>
                <v-card-title primary-title class="grey lighten-4">
                  {{ metadata.length }} Archivo<span v-if="metadata.length !== 1">s</span>
                  <v-spacer/>
                  <download-excel
                    :data="metadata"
                    type="csv"
                    :name="exportName + '.csv'"
                  >
                    <v-btn dark color="success">Exportar csv</v-btn>
                  </download-excel>
                </v-card-title>
                <v-card-text class="py-0">
                  <v-row no-gutters align="center">
                    <!-- Export File Name -->
                    <v-col cols="6" class="body-1">
                      Nombre del archivo
                    </v-col>
                    <v-col cols="6" class="pt-8">
                      <v-text-field
                        v-model="exportName"
                        color="primary"
                        suffix=".csv"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- Upload Files -->
            <!-- <v-col cols="12" lg="6">
              <v-btn
                block
                color="success"
                :disabled="$v.$invalid || !maxFileSize || !maxFiles"
                @click="uploadMetadata()"
              >Subir metadatos</v-btn>
            </v-col> -->
            <!-- Show Metadata -->
            <v-col cols="12" lg="6"> 
              <v-btn
                :disabled="metadata.length === 0"
                block
                color="primary"
                @click="showMetadataDialog()"
              >Visualizar metadatos</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <!-- Map -->
        <v-col cols="12" md="6" lg="7" class="pa-0">
          <MglMap :accessToken="accessToken" :mapStyle="mapStyle" @load="onMapLoaded" id="map"></MglMap>
        </v-col>
      </v-row>
      <!-- Steps Cards -->
      <steps-cards />
      <!-- Info Cards -->
      <info-cards />
    </v-container>
    <!-- Metadata Dialog -->
    <metadata-dialog :metadata="metadata" />
    <!-- Uploading Dialog -->
    <uploading-dialog /> 
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

import Mapbox from "mapbox-gl"
import { MglMap } from "vue-mapbox"

import DownloadExcel from 'vue-json-excel'

import StepsCards from "@/components/StepsCards"
import InfoCards from "@/components/InfoCards"
import MetadataDialog from "@/components/MetadataDialog"
import UploadingDialog from "@/components/UploadingDialog"

export default {
  components: {
    MglMap,
    DownloadExcel,
    StepsCards,
    InfoCards,
    MetadataDialog,
    UploadingDialog
  },
  mixins: [validationMixin],
  validations: {
    files: { required }
  },
  data: () => ({
    loading: false,
    exportName: 'metadata',
    files: [],
    filesHeaders: [
      { text: '#', value: "number", align: 'left', sortable: true},
      { text: 'Nombre', value: "name", align: 'left', sortable: true},
      { text: 'Tamaño', value: "size", align: 'right', sortable: true}
    ],
    // Map
    map: null,
    accessToken: 'pk.eyJ1IjoidmljdG9ybzE1IiwiYSI6ImNrM2hnNWdxczBiZjUzY210ZXl0ZXk1M2EifQ.sRIfnjcJeo1Si9Kt8H_Usw',
    mapStyle: 'mapbox://styles/mapbox/satellite-v9',
  }),
  computed: {
    metadata () {
      return this.$store.state.metadata.metadata
    },
    metadataLoaded () {
      return this.metadata.length > 0 && this.metadata.length === this.files.length
    },
    filesErrors() {
      const errors = [];
      if (!this.$v.files.$dirty) return errors;
      !this.$v.files.required && errors.push("Obligatorio.");
      !this.maxFileSize && errors.push('Las imágenes tener un tamaño menor a 10 MB cada una.')
      // !this.maxTotalSize && errors.push('El máximo total a subir debe ser menor a 100 MB.')
      !this.maxFiles && errors.push('El máximo a subir es de 1000 imágenes.')
      return errors;
    },
    maxFileSize () {
      if (this.files.length > 0) {
        let maxSize = false
        this.files.forEach(element => {
          if (element.size > 10 * Math.pow(10, 6)) maxSize = true
        })
        return !maxSize
      } else {
        return true
      }
    },
    // maxTotalSize () {
    //   const maxSize =  100 * Math.pow(10, 6)
    //   if (this.files.length > 0) {
    //     let size = 0
    //     this.files.forEach(element => {
    //       size += element.size
    //     })
    //     return size < maxSize
    //   } else {
    //     return true
    //   }
    // },
    maxFiles () {
      return this.files.length < 1000
    },
    fileSize () {
      if (this.files.length > 0) {
        let size = 0
        this.files.forEach(element => {
          size += element.size
        })
        if (size < 1000000) return (size / 1000).toFixed(1) + ' KB'
        else if (size < 1000000000) return (size / 1000 / 1000).toFixed(1) + ' MB'
        else return (size / 1000 / 1000 / 1000).toFixed(1) + ' GB'
      } else {
        return ''
      }
    }
  },
  watch: {
    metadata (val) {
      if (val && val.length > 0) {
        this.$store.commit('uploading/dialog', true)
        if (val.length === this.files.length) {
          this.$store.commit('uploading/dialog', false)
          this.uploadMetadata()
        } else {
          let upload = val.length / this.files.length
          this.$store.commit('uploading/progress', upload)
        }
      }
    }
  },
  methods: {
    showMetadataDialog () {
      this.$store.commit('metadata/dialog', true)
    },
    uploadMetadata () {
      this.$v.$touch()
      if (!this.$v.$invalid) this.$store.dispatch('metadata/upload')
    },
    addFilesClick () {
      this.$refs.file_input.click()
      this.$v.files.$touch()
    },
    addFilesDrop (event) {
      let droppedFiles = event.dataTransfer.files;
      if(!droppedFiles) return;
      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      const files = []
      droppedFiles.forEach(f => {
        files.push(f);
      });
      this.getExif(files)
    },
    removeFiles (){
      this.files = []
    },
    async getExif (droppedFiles) {
      const vm = this
      this.files = droppedFiles ? droppedFiles : this.$refs.file_input.files
      const files = this.files
      if (files.length > 0) {
        const EXIF = require('exif-js')
        this.$store.commit('metadata/initMetadata')
        this.$store.commit('uploading/dialog', true)
        let metadata = []
        EXIF.enableXmp();
        EXIF.getData(files[0], async function() {
          let GPSLongitude = await EXIF.getTag(this, 'GPSLongitude')
          let GPSLongitudeRef = await EXIF.getTag(this, 'GPSLongitudeRef')
          let GPSLatitude = await EXIF.getTag(this, 'GPSLatitude')
          let GPSLatitudeRef = await EXIF.getTag(this, 'GPSLatitudeRef')
          let coordinates = await vm.ParseDMS(GPSLongitude, GPSLongitudeRef, GPSLatitude, GPSLatitudeRef)
          vm.goToLocation(coordinates)
        });
        for (let i=0; i < files.length; i++) {
          let upload = i / files.length
          await this.$store.commit('uploading/progress', upload)
          let img = files[i]
          EXIF.getData(img, async function() {
            let exifdata = await img.exifdata
            let GPSLongitude = await EXIF.getTag(this, 'GPSLongitude')
            let GPSLongitudeRef = await EXIF.getTag(this, 'GPSLongitudeRef')
            let GPSLatitude = await EXIF.getTag(this, 'GPSLatitude')
            let GPSLatitudeRef = await EXIF.getTag(this, 'GPSLatitudeRef')
            // let xmpdata = await img.xmpdata
            let coordinates = await vm.ParseDMS(GPSLongitude, GPSLongitudeRef, GPSLatitude, GPSLatitudeRef)
            exifdata.GPSCoordinates = coordinates
            metadata.push(exifdata)
            await vm.loadMarkers(coordinates)
          });
        }
        await this.$store.commit('metadata/addMetadata', metadata)
        this.$store.commit('uploading/dialog', false)
      //   setTimeout(() => {
      //   this.uploadMetadata()
      // }, 5000)
      }
    },
    // Map
    onMapLoaded(event) {
      this.map = event.map;
    },
    goToLocation (coordinates) {
      this.map.jumpTo({
        center: coordinates,
        zoom: 15,
        speed: 1
      })
    },
    loadMarkers (coordinates) {
      new Mapbox.Marker()
        .setLngLat(coordinates)
        .addTo(this.map);
    },
    ParseDMS (longitude, longitudeDirection, latitude, latitudeDirection) {
      let long = longitude + ',' + longitudeDirection
      let lat = latitude + ',' + latitudeDirection
      let longParts = long.split(',')
      let latParts = lat.split(',')
      let longResult = this.ConvertDMSToDD(longParts[0], longParts[1], longParts[2], longParts[3])
      let latResult = this.ConvertDMSToDD(latParts[0], latParts[1], latParts[2], latParts[3])
      let result = [longResult, latResult]
      // this.goToLocation(result)
      return result
    },
    ConvertDMSToDD (degrees, minutes, seconds, direction) {   
      let dd = Number(degrees) + Number(minutes)/60 + Number(seconds)/(60*60)
      if (direction === "S" || direction === "W") dd = dd * -1
      // Don't do anything for N or E
      return dd
    }
  },
  created () {
    this.map = null
  }
};
</script>
