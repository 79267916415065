export default {
  namespaced: true,
  state: {
    dialog: false,
    progress: 0,
  },
  getters: {
    progress (state) {
      return state.progress * 100
    }
  },
  mutations: {
    progress (state, progress) {
      state.progress = progress
    },
    dialog (state, val) {
      state.dialog = val
    },
  },
  actions: {
  }
}
