<template>
  <div>
    <v-footer >
      <v-row align="center" class="text-center body-1 black--text">
        <v-col cols="12" md="9">
          Cualquier duda o sugerencia puedes escribirnos a <a class="ml-1" href="mailto:info@metadato.org">info@metadato.org</a>
        </v-col>
        <v-col cols="12" md="3">
          Metadatos &copy; {{ new Date().getFullYear() }}
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
export default {
  data: () => ({

  })
}
</script>
